exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-consentimiento-index-jsx": () => import("./../../../src/pages/consentimiento/index.jsx" /* webpackChunkName: "component---src-pages-consentimiento-index-jsx" */),
  "component---src-pages-consentimientodatospersonales-index-jsx": () => import("./../../../src/pages/consentimientodatospersonales/index.jsx" /* webpackChunkName: "component---src-pages-consentimientodatospersonales-index-jsx" */),
  "component---src-pages-consentimientousodatos-index-jsx": () => import("./../../../src/pages/consentimientousodatos/index.jsx" /* webpackChunkName: "component---src-pages-consentimientousodatos-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-curso-index-jsx": () => import("./../../../src/pages/curso/index.jsx" /* webpackChunkName: "component---src-pages-curso-index-jsx" */),
  "component---src-pages-empresa-index-jsx": () => import("./../../../src/pages/empresa/index.jsx" /* webpackChunkName: "component---src-pages-empresa-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-network-index-jsx": () => import("./../../../src/pages/network/index.jsx" /* webpackChunkName: "component---src-pages-network-index-jsx" */),
  "component---src-pages-nosotros-index-jsx": () => import("./../../../src/pages/nosotros/index.jsx" /* webpackChunkName: "component---src-pages-nosotros-index-jsx" */),
  "component---src-pages-page-404-index-jsx": () => import("./../../../src/pages/page-404/index.jsx" /* webpackChunkName: "component---src-pages-page-404-index-jsx" */),
  "component---src-pages-persona-index-jsx": () => import("./../../../src/pages/persona/index.jsx" /* webpackChunkName: "component---src-pages-persona-index-jsx" */),
  "component---src-pages-politicadedatos-index-jsx": () => import("./../../../src/pages/politicadedatos/index.jsx" /* webpackChunkName: "component---src-pages-politicadedatos-index-jsx" */)
}

